<template>
	<div class="bullMarket-bull-edit el-content">
		<a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 8 }">
			<a-form-item label="分类">
				<a-select v-model:value="form.category_id" placeholder="请选择分类">
				    <a-select-option v-for="(item,index) in category" 
						:key="index" 
						:value="item.id">{{item.name}}
					</a-select-option>
				</a-select>
			</a-form-item>
			<a-form-item label="名称">
				<a-input placeholder="名称" v-model:value="form.title"></a-input>
			</a-form-item>
			<a-form-item label="图片展示" >
				<kd-img-select :mutil="true" :src="form.images" @change="(url)=>{ form.images = url}"></kd-img-select>
			</a-form-item>
			<a-form-item label="价格">
				<a-input placeholder="价格" v-model:value="form.price" addon-after="元"></a-input>
			</a-form-item>
			<a-form-item label="预缴定金">
				<a-input placeholder="预缴定金" v-model:value="form.deposit_price" addon-after="元"></a-input>
			</a-form-item>
			<a-form-item label="库存">
				<a-input placeholder="库存" v-model:value="form.num" type="number"></a-input>
			</a-form-item>
			<a-form-item label="最低购买数量">
				<a-input placeholder="最低购买数量" v-model:value="form.min_buy" type="number"></a-input>
			</a-form-item>
			<a-form-item label="联系人姓名">
				<a-input placeholder="联系人姓名" v-model:value="form.contact_name"></a-input>
			</a-form-item>
			<a-form-item label="联系人电话">
				<a-input placeholder="联系人电话" v-model:value="form.contact_mobile"></a-input>
			</a-form-item>
			<a-form-item label="是否公开">
				<a-radio-group v-model:value="form.is_public">
					<a-radio :value="1">公开</a-radio>
					<a-radio :value="0">不公开</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item label="详情">
				<tinymce-editor v-model="form.detail"></tinymce-editor>
			</a-form-item>
			<a-form-item label="排序">
				<a-input v-model:value="form.sort" type="number"></a-input>
			</a-form-item>
			<a-form-item :wrapper-col="{ offset: 2 }">
				<a-space>
					<a-button type="primary" @click="saveBull">保存</a-button>
					<a-button @click="$router.go(-1)">返回</a-button>
				</a-space>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import bullModel from '@/api/addons/bull'
export default{
	setup(){
		const state = reactive({
			form:{
				id:0,
				num:'',
				sort:99,
				title:'',
				price:'',
				detail:'',
				images:[],
				min_buy:'',
				is_public:'',
				category_id:'',
				contact_name:'',
				deposit_price:'',
				contact_mobile:'',
			},
			category:[],
		})
		const opt = useRoute().query
		if( opt.id ){
			bullModel.getBullDetail(opt.id,Object.keys(state.form),res=>state.form = res)
		}

		bullModel.getBullCategory(1,999,null,res=>state.category = res.list)

		const saveBull = ()=>bullModel.addOrEditBull(state.form)

		return{
			...toRefs(state),
			saveBull
		}
	}
}
</script>

<style>
</style>
